import React, { useState, useEffect, useMemo } from "react";
import {
  Table, Thead, Tbody, Td,
  Tr, Th, Box, HStack, Spacer,
  Text,
  Button
  } from '@chakra-ui/react'
import { 
  GetTableHeaders, SKURowNames, GetUniqueValues 
} from "./SKUCanvasHelper";
import { DataRowReport } from './DataRows';
import { CustomSpinner } from "../../../Spinner";
import { ProductLine, Category,Dealer } from './Filter';
import '../../../../index';
import useFetch from "../../../../hooks/useFetchWithMsal";
import ExportReportsButton from "./ExportReportsButton"


function SKUReport({userProfile}) {

  const [{ data, loading:isLoading, }, fetchData] = useFetch([]);
  const uniqueDates = GetUniqueValues(data, 'date'); // Column header values for dates
  const tableHeaders = GetTableHeaders(uniqueDates); // All the table headers
  const uniqueProductCategory = useMemo(() => GetUniqueValues(data, 'Product_Category'), [data]);
  const [selectedProductLine, setSelectedProductLine] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const uniqueDealers=GetUniqueValues(data,'dealer_no_all')
  const uniqueProductLine = GetUniqueValues(data, 'Product_Line');
  const [filteredUniqueProductCategory, setFilteredUniqueProductCategory] = useState(uniqueProductCategory);
  const [selectedDealer,setSelectedDealer]=useState(uniqueDealers[0])


  const filteredData = data.filter(
    (item) =>
      (!selectedProductLine || item['Product_Line'] === selectedProductLine) &&
      (!selectedCategory || item['Product_Category'] === selectedCategory) &&
      (item['dealer_no_all']===selectedDealer)
  );

  /*
  Group the data based on SKU code
  */
  const groupedData = filteredData.reduce((grouped, item) => {
    const dealer_skuCode = item["dealer_no"]+"-"+item['SKU_code'];
    grouped[dealer_skuCode] = grouped[dealer_skuCode] || [];
    grouped[dealer_skuCode].push(item);
    return grouped;
  }, {});

  /* 
  Create each row for the data recevied
  */
  const renderDataRows = (data) => {
    return SKURowNames.map((rowName, index) => (
      <DataRowReport
        key={`${index}-${data[0]['SKU_code']}-${data[0]['Product_Line']}-${data[0]['dealer_no']}`}
        rowName={rowName}
        index={index}
        uniqueDates={uniqueDates}
        data={data}
      />
    ));
  };

  const dataRows = Object.values(groupedData).map(renderDataRows); // All the data rows

  useEffect(() => {
    if (!userProfile) return;
    fetchData(`/regional_products?userID=${userProfile["ID"]}`, {method: "GET"}).then((data) => {
      // console.log("regional_products: ",data)
      const uniqueProductCategory = GetUniqueValues(data, 'Product_Category');
      const uniqueDealers=GetUniqueValues(data,'dealer_no_all')
      setFilteredUniqueProductCategory(uniqueProductCategory);
      setSelectedDealer(uniqueDealers[0])
    })
  }, [userProfile,setFilteredUniqueProductCategory ,fetchData]);

  /* 
  Show Spinner until the data is received
  */
  if (isLoading) return <CustomSpinner text="Loading Report..." />

  const handleProductLineChange = (selectedOption) => {
    setSelectedProductLine(selectedOption);
    setSelectedCategory('');
    const filteredCategories = data
    .filter(item => selectedOption ? item['Product_Line'] === selectedOption : true)
    .map(item => item['Product_Category']);
    setFilteredUniqueProductCategory([...new Set(filteredCategories)]);
  }
  const handleCategoryChange = (selectedOption) => setSelectedCategory(selectedOption);
  const handleDealerChange = (selectedOption)=>setSelectedDealer(selectedOption);

 


  return (
    <Box height="70vh" overflow="auto">
      <HStack spacing={4}>
        <ProductLine value={selectedProductLine} options={uniqueProductLine} onChange={handleProductLineChange}/>
        <Category value={selectedCategory} options={filteredUniqueProductCategory} onChange={handleCategoryChange}/>
        <Dealer value={selectedDealer} options={uniqueDealers} onChange={handleDealerChange}/>
        
        
        <ExportReportsButton userProfile={userProfile} selectedProductLine={selectedProductLine} selectedCategory={selectedCategory} selectedDealer={selectedDealer} />
        <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >* Data in units</Text>
        </HStack>
      <Box id="printableArea">
        <Table id="skureport">
          <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <Tr>
              <Th textAlign>Dealer Number</Th>
              <Th textAlign>Product Line</Th>
              <Th textAlign>SKU Code</Th>
              <Th textAlign>Key Figure</Th>
              {tableHeaders}
              <Th textAlign style={{color:"black"}}>Total</Th>
            </Tr>
          </Thead>
          <Tbody>{
                dataRows.length > 0 ? 
                  (dataRows):
                  (
                  <Tr>
                    <Td colSpan={16} textAlign>
                      <Text>No Data Available. Please remove filters.</Text>
                    </Td>
                  </Tr>
                  )
              }</Tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default SKUReport;
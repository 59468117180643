import React, { useState, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Select,
    Flex,
    FormLabel,
    HStack,
    Box,
    Text,
    Tooltip,
    Spacer
} from '@chakra-ui/react';
import {ApplyCellBg } from '../../../../Constants';
import { ParseOtherTableDataReport, RowNameMappingOtherTable } from './OtherCanvasHelper';
import '../../../../index';
import useFetch from '../../../../hooks/useFetchWithMsal';
import { CustomSpinner } from '../../../Spinner';
import { FormatDateToMonthYear } from '../../../../Constants';
import ExportReportsButton from "./ExportReportsButton"


export default function OtherTableReport({ userProfile }) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');
    const [dealer_no_all,setDealer_no_all]=useState([])
    const [dealer_filter,setDealer_filter]=useState(dealer_no_all[0])
    const [{loading}, fetchData] = useFetch([]);
    
    const categories = [...new Set(Object.values(RowNameMappingOtherTable).map(arr => arr[0]))];

    useEffect(() => {
        fetchData(`/regionalDistributor?userID=${userProfile["ID"]}`, {method: "GET"}).then((jsonData) => {
            ParseOtherTableDataReport(jsonData, setTableData,setDealer_no_all,setDealer_filter)
        })
    }, [userProfile]);

    if (loading) return <CustomSpinner text="Loading Report..." />

    return (
        <Box height="70vh">
        <HStack>
            <Flex alignItems="center">
                <FormLabel htmlFor="category" 
                    mb="0" mr="2"
                    color="gray.600" fontWeight="bold"
                    fontSize="sm">
                    Category
                </FormLabel>
                <Select id="category" value={filter} onChange={e => setFilter(e.target.value)}>
                    <option value="">All</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </Select>
            </Flex>
            <Flex alignItems="center">
                <FormLabel htmlFor="dealer_no" 
                    mb="0" mr="2"
                    color="gray.600" fontWeight="bold"
                    fontSize="sm">
                    Dealer No
                </FormLabel>
                <Select id="dealer_no" value={dealer_filter} onChange={e => setDealer_filter(e.target.value)}>
                    {dealer_no_all && dealer_no_all.map((dealer_no, index) => (
                    <option key={index} value={dealer_no}>{dealer_no}</option>
                ))}
                </Select>
            </Flex>           
            
            <ExportReportsButton userProfile={userProfile} category={filter} dealerNo={dealer_no_all} />

            <Spacer />
        <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >*Shows represent cooked shows</Text>
        </HStack>
        <Box height="65vh" overflow="auto">
            <Table id="printableArea">
                <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                    <Tr>
                        <Th>Dealer No</Th>
                        <Th textAlign>Category</Th>
                        <Th textAlign>Key Figure</Th>
                        {tableData.dates &&
                        tableData.dates.map((date, index) => <Th key={index}>{FormatDateToMonthYear(date)}</Th>)}
                        <Th textAlign style={{color:"black"}}>Total</Th>
                    </Tr>
                </Thead>
                <Tbody>
                { tableData?.dealer_no && tableData.dealer_no.filter(deal_no=>deal_no===dealer_filter).map((deal_no) => {
                    return ((tableData.dealer_wise_data[deal_no].data.length>0)?
                    Object.keys(tableData.dealer_wise_data[deal_no].data[0])
                    .filter(key => RowNameMappingOtherTable[key][0] === filter || filter === '')
                    .map((key, index) => (
                        <Tr key={index}>
                            <Td textAlign style={ApplyCellBg(key)}><Tooltip label={tableData.dealer_wise_data[deal_no].dealer_name} aria-label='Dealer Name'>{String(tableData.dealer_wise_data[deal_no].dealer_no)}</Tooltip></Td>
                            <Td textAlign style={ApplyCellBg(key)}>{RowNameMappingOtherTable[key][0]}</Td>
                            <Td textAlign style={ApplyCellBg(key)}>{RowNameMappingOtherTable[key][1]}</Td>
                            {tableData.dates &&
                                tableData.dealer_wise_data[deal_no].data
                                .filter((item) => item.hasOwnProperty(key))
                                .map((item, index) => 
                                    <Td style={ApplyCellBg(key)} key={index} textAlign>
                                        {
                                            item[key] !== null && item[key] !== undefined
                                            ?key.includes('revenue')? `$${Math.ceil(parseFloat(item[key]).toFixed(0)).toLocaleString('en-US')}`
                                            : parseFloat(item[key]).toFixed(0):""
                                        }
                                    </Td>
                                )}
                            <Td textAlign style={{...ApplyCellBg(key),fontWeight:"bold"}}>
                                {
                                    tableData.dealer_wise_data[deal_no].totals[key] !== null && tableData.dealer_wise_data[deal_no].totals[key] !== undefined && !isNaN(tableData.dealer_wise_data[deal_no].totals[key])
                                    ? key.includes('revenue') ?`$${Math.ceil(parseFloat(tableData.dealer_wise_data[deal_no].totals[key]).toFixed(0)).toLocaleString('en-US')}`
                                    : parseFloat(tableData.dealer_wise_data[deal_no].totals[key]).toFixed(0):""
                                }
                            </Td>
                        </Tr>
                    )):<Tr><Td></Td><Td></Td><Td>No Data</Td></Tr>
                    )})}
                </Tbody>
            </Table>
            </Box>
        </Box>
    );
}

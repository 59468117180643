import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  HStack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Spacer,
  Text,
  Button,
} from "@chakra-ui/react";
import SaveButton from "../../Save";
import useFetch from "../../../hooks/useFetchWithMsal";
import { ApplyCellBg, exportToExcel } from "../../../Constants";
import { FormatDateToMonthYear } from "./RegionalCanvasHelper";
import EditableRegionalCell from "./EditableRegionalCell";
import { pivotParseData, updateTotals } from "./RegionalCanvasHelper";
import { rowMapping } from "./RegionalCanvasHelper";
import { IoMdPrint } from "react-icons/io";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { useMsal,useAccount } from '@azure/msal-react';

export default function RegionalTable({
  userProfile,
  error,
  saveEnabled,
  data,
  setDataSaved,
  account_id,
}) {
  const [, updateData] = useFetch();
  const [{ data1, loading, error1}, fetchData] = useFetch();
  const home_account_id = sessionStorage.getItem('home_account_id')
  // const [json_data,setJson_data]=useState(data)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [changesTracker, setChangesTracker] = useState({});
  const [pivotedData, setPivotedData] = useState({});
  const [totals, setTotals] = useState({});
  const [excelData, setExcelData] = useState([]);
  const {accounts } = useMsal();
  const index = accounts.findIndex((val)=>val.homeAccountId===home_account_id)
  const account = useAccount(home_account_id?accounts[index] : null);
  const [activeRegionId ,setActiveRegionId]=useState(null)

  
  /**
   * Show Custom Spinner while data is being fetched.
   */
  //   if (loading) return <CustomSpinner text="Loading Editor..." />
    useEffect(()=>{
      pivotParseData(data, setPivotedData, setTotals);
      
        fetchData(`/regional_leader_id`, { method: "GET" }).then((data) => {

          console.log("regional_leader_id: ", data["result"][0])
          // sessionStorage.setItem("regionList",JSON.stringify(data))

          let aci = JSON.parse(sessionStorage.getItem("aci"))
          if(aci){
            setActiveRegionId(aci["ID"])
            // console.log(aci)
          }
          else{
            setActiveRegionId(data["result"][0]['ID'])
            sessionStorage.setItem("aci",JSON.stringify(data["result"][0]))
          }
        })
      

    },[account,data,fetchData])


  useEffect(()=>{
    getExcelData();
  }, [activeRegionId])


  const uploadData = () => {
    setIsButtonDisabled(true);
    let data = [];
    Object.entries(changesTracker).forEach(([key, newValue]) => {
      const [rowId, columnID, forecast_version_date] = key.split(",");
      data.push({
        date: columnID,
        newValue: newValue === "" ? null : parseFloat(newValue),
        sku_code: rowId,
        regional_leader_id: userProfile,
        forecast_version: forecast_version_date,
        update_date: new Date().toISOString(),
        update_by: account_id,
      });
    });
    updateData("/regionalViewUpdateNonForecast", { method: "PUT", body: data })
      .then((data) => {
        if (data.Error) return;
      })
      .then(() => {
        updateTotals(
          totals,
          pivotedData,
          setTotals,
          setPivotedData,
          changesTracker
        );
        setDataSaved(true);
        setChangesTracker({});
        getExcelData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangesTracker = useCallback(
    (newValue, columnID, rowId, regional_leader_id, forecast_version_date) => {
      setIsButtonDisabled(false);
      setDataSaved(false);
      setChangesTracker((prevChanges) => {
        const updatedChanges = { ...prevChanges };
        const key = `${rowId},${columnID},${forecast_version_date},${regional_leader_id}`;
        updatedChanges[key] = newValue;
        return updatedChanges;
      });
    },
    [setIsButtonDisabled, setDataSaved, setChangesTracker]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const getExcelData = () => {
    if (activeRegionId)
    {fetchData(`/regionalViewExcelData?userID=${activeRegionId}`, { method: "GET" }).then((data) => {
      setExcelData(data['excel_data']);
    })}
  }


  return (
    <Box minHeight="90vh">
      <HStack spacing={4}>
        <SaveButton
          isButtonDisabled={!saveEnabled || isButtonDisabled}
          uploadData={uploadData}
        />

        <Button
          onClick={onOpen}
          mt={4}
          px={6}
          py={2}
          fontSize="md"
          fontWeight="bold"
        >
          Get Reports
        </Button>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader textAlign="center">
              Download Report
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody></AlertDialogBody>
            <AlertDialogFooter justifyContent="center">
              <Button
                leftIcon={<IoMdPrint />}
                mx={2}
                onClick={() => window.print()}
                colorScheme="blue"
              >
                Print to PDF
              </Button>
              <Button
                leftIcon={<IoCloudDownloadOutline />}
                mx={2}
                onClick={() => exportToExcel(excelData, 'regionalViewReport')}
                colorScheme="blue"
              >
                Download to Excel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        <Spacer />
        {/* <Text
        fontSize="sm" // Adjust the font size to your preference
        fontWeight="bold" // Apply bold font weight to the caption
        color="gray.500" // Use a gray color for the caption
        marginBottom="2" // Add some bottom margin for spacing
        >* Data in units</Text> */}
      </HStack>
      <Box height="85vh" overflow="auto" textAlign="center" id="printableArea">
        <Table textAlign="center">
          <Thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 2,
            }}
            textAlign="center"
          >
            <Tr key="Table_Headers">
              {/* <Th textAlign>Warehouse</Th> */}
              <Th
                key="Key_figure"
                textAlign="start"
                style={{ minWidth: "200px" }}
              >
                Key Figure
              </Th>
              {/* {tableHeaders} */}
              {Object.keys(pivotedData).map((date) => {
                
                return (
                  <Th key={`${date}-Header`} textAlign>
                    {FormatDateToMonthYear(date)}
                  </Th>
                );
              })}
              <Th key="Total" style={{ fontWeight: "bold", color: "black" }}>
                Total
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* {pivotedData && pivotedData.forEach((row) => {
                return <td></td>
            }); */}
            {/* <p>{JSON.stringify(pivotedData[Object.keys(pivotedData)[0]])}</p> */}
            {rowMapping.map((rowName) => {
              let displayName = rowName.slice(0, rowName.indexOf("-")).trim();
              // console.log("NAME", displayName);
              // if (rowName == "Prev Fcst - Focus Dealer")
              if (rowName === "Current Fcst - Total") {
                return (
                  <Tr
                    key={`${rowName}-Main-Row-Total`}
                    style={{ borderTop: "2px solid lightGray" }}
                  >
                    <Td
                      key={`${rowName}-label}`}
                      textAlign="start"
                      style={{
                        ...ApplyCellBg("qty_override_forecast"),
                        fontWeight: "bold",
                      }}
                      pl={16}
                    >
                      {displayName}
                    </Td>
                    {Object.keys(pivotedData).map((date) => {
                      return (
                        <EditableRegionalCell
                          key={`${date}-Final_Forecast`}
                          keyValue={`${date}-Final_Forecast`}
                          value={
                            pivotedData[date]["Current Fcst - Total"] &&
                            pivotedData[date]["Current Fcst - Total"]
                          }
                          FocusDealerForecast={
                            pivotedData[date]["Current Fcst - Focus Dealer"]
                          }
                          columnID={date}
                          onUpdate={handleChangesTracker}
                          dealer_type="All others"
                          regional_leader_id={userProfile}
                          forecast_version_date={
                            pivotedData[date]["Forecast Version"]
                          }
                          current_revenue_forecast={
                            pivotedData[date]["Current Adjusted Revenue"]
                          }
                          saveEnabled={saveEnabled}
                          pivotedData={pivotedData}
                          setPivotedData={setPivotedData}
                        />
                      );
                    })}
                    <Td
                      key={`${rowName}-label-Totals`}
                      textAlign="start"
                      style={{ ...ApplyCellBg("qty_override_forecast") }}
                    >
                      {totals[rowName] && totals[rowName] !== 0
                        ? parseFloat(totals[rowName]).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                          })
                        : ""}
                    </Td>
                  </Tr>
                );
              } else {
                let row = (
                  <Tr
                    key={`${rowName}-Main-Row`}
                    style={{ borderTop: "2px solid lightGray" }}
                  >
                    {(displayName === "Current Fcst") |
                    (displayName === "Calc Current Fcst") ? (
                      <>
                        <Td
                          textAlign="start"
                          pl={16}
                          key={`${rowName}-label`}
                          style={{
                            ...ApplyCellBg(displayName),
                            fontWeight: "bold",
                          }}
                        >
                          {displayName}
                        </Td>
                        {Object.keys(pivotedData).map((date) => {
                          return (
                            <Td
                              key={`${date}-${rowName}`}
                              style={{
                                ...ApplyCellBg(displayName),
                                fontWeight: "bold",
                              }}
                              textAlign
                            >
                              {pivotedData[date][rowName] &&
                                Number(
                                  pivotedData[date][rowName]
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                })}
                            </Td>
                          );
                        })}
                        <Td
                          style={{
                            ...ApplyCellBg(displayName),
                            fontWeight: "bold",
                          }}
                          key={`${rowName}-label-Totals`}
                        >
                          {totals[rowName] && totals[rowName] !== 0
                            ? Number(totals[rowName]).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 0,
                              })
                            : ""}
                        </Td>
                      </>
                    ) : (
                      <>
                        <Td textAlign="start" pl={16} key={`${rowName}-label`}>
                          {displayName}
                        </Td>
                        {Object.keys(pivotedData).map((date) => {
                          return (
                            <Td key={`${date}-${rowName}`} textAlign>
                              {pivotedData[date][rowName] &&
                                Number(
                                  pivotedData[date][rowName]
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                })}
                            </Td>
                          );
                        })}
                        <Td
                          style={{ fontWeight: "bold" }}
                          key={`${rowName}-label-Totals`}
                        >
                          {totals[rowName] && totals[rowName] !== 0
                            ? Number(totals[rowName]).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 0,
                              })
                            : ""}
                        </Td>
                      </>
                    )}
                  </Tr>
                );
                let headers = ["Focus Dealer", "Non Focus Dealer", "Total"];
                for (let index in headers) {
                  if (rowName === "YAG History - " + headers[index]) {
                    row = (
                      <>
                        <Tr
                          key={`${rowName}-Main-Row-${headers[index]}`}
                          style={{ borderTop: "2px solid lightGray" }}
                        >
                          <Td style={{ fontWeight: "bold" }}>
                            {headers[index]}
                          </Td>
                        </Tr>
                        {row}
                      </>
                    );
                  }
                }

                return row;
              }
            })}

            {/* {Object.keys(pivotedData).map((date) => {
                            const currentAdjustedRevenueForecast = pivotedData[date]["Focus Dealer Forecast"] + pivotedData[date]["Non-focus dealer forecast stat"]
                            const gap = currentAdjustedRevenueForecast - pivotedData[date]["StatRevenueBaseline"]
                            return <Tr>
                                <Td></Td>
                                <Td>{pivotedData[date]["StatRevenueBaseline"]}</Td>
                                <Td>{pivotedData[date]["Last Cycle Focus Dealers Forecast"]}</Td>
                                <Td>{pivotedData[date]["Focus Dealer Forecast"]}</Td>
                                <Td>{pivotedData[date]["Last Cycle Non-Focus Dealers Forecast"]}</Td>
                                <Td>{pivotedData[date]["Non-focus dealer forecast stat"]}</Td>
                                <Td>{pivotedData[date]["Last Cycle Regional Revenue Forecast"]}</Td>
                                <Td>{currentAdjustedRevenueForecast}</Td>
                                <Td>{gap}</Td>
                            </Tr>
                        })} */}
            {error && (
              <Tr>
                <Td colSpan={16} textAlign>
                  <Text> Error Occured: {error.message} </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import {
  Button
  } from '@chakra-ui/react'
import { IoMdPrint } from 'react-icons/io';
import {
    Box,
    Center,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
  } from "@chakra-ui/react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { exportToExcel } from '../../../../Constants';
import useFetch from "../../../../hooks/useFetchWithMsal";
import { CustomSpinner } from "../../../Spinner";


function ExportReportsButton ({userProfile, category, dealerNo}) {
    const [{ data, loading:isLoading, }, fetchData] = useFetch([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    if (isLoading) return (
      <>
      <Center h="100vh" w="100%" position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)">
        <Box
        p={6}
        bg="rgba(255, 255, 255, 1)" // Semi-transparent white background
        borderRadius="md"
        backdropFilter="blur(8px)" // Apply the blur
        boxShadow="lg"
      >
          <CustomSpinner text="Loading Report..." />

        </Box>
        </Center>
      </>
    );

    return (
        <>
            <Button
          onClick={onOpen}
          // mt={4}
          // px={6}
          // py={2}
          fontSize="md"
          fontWeight="bold"
        >
          Get Reports
        </Button>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader textAlign="center">
              Download Report
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody></AlertDialogBody>
            <AlertDialogFooter justifyContent="center">
              <Button
                leftIcon={<IoMdPrint />}
                mx={2}
                onClick={() => window.print()}
                colorScheme="blue"
              >
                Print to PDF
              </Button>
              <Button
                leftIcon={<IoCloudDownloadOutline />}
                mx={2}
                onClick={() => {
                      fetchData(`/regionalDistributorExcelData?userID=${userProfile["ID"]}`,
                         {method: "POST", body: {'category': category, 'dealerNo': dealerNo}}, 
                      ).then((jsonData) => {
                          exportToExcel(jsonData['excel_data'], 'regionalDistributorReport')
                  });
                }}
                colorScheme="blue"
              >
                Download to Excel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        </>
    )
}
export default ExportReportsButton;